h1 {
  margin: 0 0 0.5rem;
  letter-spacing: 3px;
}

h2 {
  margin: 0 0 0.5rem;
  letter-spacing: 2px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding: 0;
}